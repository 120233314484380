<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="auto">
              <v-btn class="label" text color="transparent">
                <div
                  class="font-weight-regular secondary--text text-capitalize"
                >
                  {{ $t('page_company_fl_goal_setting_create_new_text') }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <v-row>
                  <v-col offset="1" cols="12" md="4">
                    <v-card class="past-year-reference-card no-shadow">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                :items="getCompanyBusinessYears"
                                item-value="term"
                                item-text="term"
                                v-model="selectedBusinessYear"
                                return-object
                                flat
                                dense
                                class="term-select"
                                color="primary"
                                hide-details
                                append-icon="$customChevronDown"
                                @change="getDataFromApi()"
                              >
                                <template v-slot:item="{ item }">
                                  {{
                                    $t('year_label', {
                                      number: item.term,
                                      start: item.start_date.slice(0, -3),
                                      end: item.end_date.slice(0, -3)
                                    })
                                  }}
                                </template>
                                <template v-slot:selection>
                                  {{ getSelectedTermLabel }}
                                </template>
                              </v-select>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_sales_performance_text') }}
                                </v-col>
                                <v-col cols="6">
                                  {{
                                    getCompanyFLGoal.sales.total | toCurrency
                                  }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_cost_performance_text') }}
                                </v-col>
                                <v-col cols="6">
                                  {{
                                    getCompanyFLGoal.costs.total | toCurrency
                                  }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12"></v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_table_header_f_percentage') }}
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.f_cost
                                      .percentage
                                  }}%
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.f_cost
                                      .amount | toCurrency
                                  }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_table_header_l_percentage') }}
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.l_cost
                                      .percentage
                                  }}%
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.l_cost
                                      .amount | toCurrency
                                  }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_table_header_fl_percentage') }}
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.total
                                      .percentage
                                  }}%
                                </v-col>
                                <v-col cols="3">
                                  {{
                                    getCompanyFLGoal.costs.fl_costs.total
                                      .amount | toCurrency
                                  }}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col offset="2" cols="12" md="4">
                    <v-card class="no-shadow">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <validation-provider
                                v-slot="{ errors }"
                                name="term"
                                rules="required"
                              >
                                <v-select
                                  :items="getCompanyBusinessYears"
                                  item-value="term"
                                  item-text="term"
                                  v-model="selectedBusinessYear"
                                  return-object
                                  flat
                                  dense
                                  class="term-select term-display"
                                  color="primary"
                                  :error-messages="errors"
                                  :hide-details="!errors"
                                  append-icon=""
                                >
                                  <template v-slot:item="{ item }">
                                    {{
                                      $t('year_label', {
                                        number: item.term,
                                        start: item.start_date.slice(0, -3),
                                        end: item.end_date.slice(0, -3)
                                      })
                                    }}
                                  </template>
                                  <template v-slot:selection>
                                    {{ getTermLabel }}
                                  </template>
                                </v-select>
                              </validation-provider>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_sales_performance_text') }}
                                </v-col>
                                <v-col cols="6">
                                  {{ currentTermSale | toCurrency }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="text-right px-8">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('page_company_fl_goal_setting_cost_performance_text') }}
                                </v-col>
                                <v-col cols="6">
                                  {{ currentTermCost | toCurrency }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col v-if="editMode" cols="12" class="text-right">
                              <v-row>
                                <v-col cols="6">
                                  {{ $t('id') }}
                                </v-col>
                                <v-col cols="2" class="primary--text">
                                  {{ getCompanyFLGoal.id }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="px-8">
                              <v-row align="center">
                                <v-col cols="6" class="text-right">F%</v-col>
                                <v-col cols="2" class="pl-4 pr-0">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="f_ratio"
                                    :rules="`required|max:${100 - lRatio}`"
                                  >
                                    <v-text-field
                                      outlined
                                      dense
                                      :error-messages="errors"
                                      :hide-details="errors.length === 0"
                                      class="custom-outlined"
                                      v-model.number="fRatio"
                                    >
                                    </v-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="1" class="pl-0 text-right">
                                  %
                                </v-col>
                                <v-col cols="3" class="text-right">
                                  {{ currentTermFoodCost | toCurrency }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="px-8">
                              <v-row align="center">
                                <v-col cols="6" class="text-right">L%</v-col>
                                <v-col cols="2" class="pl-4 pr-0">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="l_ratio"
                                    :rules="`required|max:${100 - fRatio}`"
                                  >
                                    <v-text-field
                                      outlined
                                      dense
                                      :error-messages="errors"
                                      :hide-details="errors.length === 0"
                                      class="custom-outlined"
                                      v-model.number="lRatio"
                                    >
                                    </v-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="1" class="pl-0 text-right">
                                  %
                                </v-col>
                                <v-col cols="3" class="text-right">
                                  {{ currentTermLaborCost | toCurrency }}
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="px-8">
                              <v-row align="center">
                                <v-col cols="6" class="text-right">FL%</v-col>
                                <v-col cols="3" class="text-right">
                                  {{ flRatio }} %
                                </v-col>
                                <v-col cols="3" class="text-right">
                                  {{ currentTermFLCost | toCurrency }}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-btn
                      type="submit"
                      color="#BD6A6A"
                      :disabled="formStatus"
                      :loading="formStatus"
                      class="text-capitalize font-weight-regular mx-4"
                      depressed
                      small
                      text
                      min-width="120"
                      exact
                      :to="{ name: 'CompanyDetailFLGoal' }"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="formStatus"
                      :loading="formStatus"
                      class="text-capitalize font-weight-regular mx-4"
                      depressed
                      small
                      min-width="120"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </validation-observer>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'index',

  created() {
    this.selectedBusinessYear = this.getCompanyBusinessYear
    this.getDataFromApi()
  },

  computed: {
    ...mapGetters([
      'getCompanyBusinessYears',
      'getCompanyBusinessYear',
      'getCompanyFLGoal'
    ]),

    getTermLabel() {
      if (this.getCompanyBusinessYear === null) {
        return ''
      }

      return this.$t('year_label', {
        number: this.getCompanyBusinessYear.term,
        start: this.getCompanyBusinessYear.start_date.slice(0, -3),
        end: this.getCompanyBusinessYear.end_date.slice(0, -3)
      })
    },

    getSelectedTermLabel() {
      if (this.selectedBusinessYear === null) {
        return ''
      }

      return `${this.$t('reference')} | ${this.$t('year_label', {
        number: this.getCompanyBusinessYear.term,
        start: this.getCompanyBusinessYear.start_date.slice(0, -3),
        end: this.getCompanyBusinessYear.end_date.slice(0, -3)
      })}`
    },

    currentTermFoodCost() {
      return (this.fRatio / 100) * this.currentTermCost
    },

    currentTermLaborCost() {
      return (this.lRatio / 100) * this.currentTermCost
    },

    currentTermFLCost() {
      return Math.round(this.currentTermFoodCost + this.currentTermLaborCost)
    },

    flRatio() {
      return this.fRatio + this.lRatio
    }
  },

  data() {
    return {
      loading: false,
      fields: [],
      formStatus: false,
      selectedBusinessYear: null,
      fRatio: 0,
      lRatio: 0,
      editMode: false,
      currentTerm: 0,
      currentTermSale: 0,
      currentTermCost: 0
    }
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      this.$store
        .dispatch('COMPANY_FL_GOAL_GET', {
          id: this.$route.params.id,
          term: this.selectedBusinessYear.term
        })
        .then(() => {
          if (
            this.selectedBusinessYear.term === this.getCompanyBusinessYear.term
          ) {
            this.currentTermSale = this.getCompanyFLGoal.sales.total
            this.currentTermCost = this.getCompanyFLGoal.costs.total
          }
        })
        .finally(() => (this.loading = false))
    },

    submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        let data = {}

        data.id = this.$route.params.id
        data.f_ratio = this.fRatio
        data.l_ratio = this.lRatio
        data.term = this.getCompanyBusinessYear.term

        this.$store.dispatch('COMPANY_FL_GOAL_CREATE', data).then(result => {
          if (result.status === 200) {
            this.$refs.observer.reset()
            this.fRatio = 0
            this.lRatio = 0
            this.$router.push({
              name: 'CompanyDetailFLGoal'
            })
          }
          this.formStatus = false
        })
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
